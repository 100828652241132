import type { TransitionProps } from 'vue'

export const isTransition = (
  possibleTransition: TransitionProps | true,
): possibleTransition is TransitionProps =>
  (possibleTransition as TransitionProps).name !== undefined

export default defineNuxtRouteMiddleware((to, { path: fromPath }) => {
  const previousStep = getPreviousRegisterStepByPath(to.path)
  const pageTransition = to.meta.pageTransition
  if (!pageTransition || !isTransition(pageTransition)) return

  pageTransition.name =
    previousStep?.route === fromPath ? 'slide-left' : 'slide-right'
})
